import { Chip, TooltipHover } from "get-life-storybook-ts";
import { formatPrice } from "../../../../utils/staticData";

export const getPrices = (
  translate: (key: string) => string,
  element: {
    promotedYearlyPrice: number;
    yearlyPrice: number;
    promoDescription: string;
  }
) => {
  if (
    element.promotedYearlyPrice !== 0 &&
    element.promotedYearlyPrice !== element.yearlyPrice
  ) {
    return (
      <>
        <TooltipHover
          content={
            <>
              <s>{element.yearlyPrice} €</s>
              <p>{element.promoDescription}</p>
            </>
          }
        >
          <Chip icon={"TagOutlineIcon"} type="default" className={"h-6"} />
        </TooltipHover>
        <p>{formatPrice(element.promotedYearlyPrice)}</p>
      </>
    );
  } else {
    if (element.yearlyPrice < 3) {
      return <p>{translate("table.capital.notAvailable")}</p>;
    }

    return <p>{formatPrice(element.yearlyPrice)}</p>;
  }
};
