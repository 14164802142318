import { useState, useEffect, useContext } from "react";
import PaymentMain from "./PaymentMain";

import apiLeadPrice from "../../api/request/apiLeadPrice";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import CouponConditions from "../Rebranding/organisms/Modal/CouponConditions/CouponConditions.component";
import { I18nContext } from "../../contexts/i18n.context";
import HttpCouponsRepository from "../../api/request/Coupons/Coupons.service";
import HttpLeadRepository from "../../api/request/Lead/lead.service";
import CouponSelectorModal from "../components/CouponSelectorModal/CouponSelectorModal";

const Payment = () => {
  const { leadId, token, setShowNotification, brokerId, broker, utm, setLoading, DOMAIN } = useContext(ContextGetlife);
  const {
    state: { translate },
  } = useContext(I18nContext);

  const [election, setElection] = useState(true);
  const [paymentCard, setPaymentCard] = useState(false);
  const [paymentSepa, setPaymentSepa] = useState(false);
  const [productCard, setProductCard] = useState(false);
  const [couponInfo, setCouponInfo] = useState(false);
  const [showModalCoupon, setShowModalCoupon] = useState(false);
  const [sqlInfo, setSqlInfo] = useState({});
  const [promoLoading, setPromoLoading] = useState(false);
  const [validateCoupon, setValidateCoupon] = useState();

  const leadRepository = new HttpLeadRepository(token);
  const couponsRepository = new HttpCouponsRepository(leadId, token);

  const handlePaymentCard = () => {
    setPaymentCard(!paymentCard);
    setElection(false);
  };

  const handlePaymentSepa = () => {
    setPaymentSepa(!paymentSepa);
    setElection(false);
  };

  const undoSelectedPaymentMethod = () => {
    setElection(true);
    setPaymentSepa(false);
    setPaymentCard(false);
  }

  const fetchPaymentPrices = async () => {
    setLoading(true);
    try {
      const response = await leadRepository.getPaymentPricingCapitals(leadId);
      setSqlInfo(response)
      setLoading(false);
    
    } catch (error) { }
    setLoading(false);
  };

  useEffect(() => {
    if (DOMAIN !== "sql_es") {
      apiLeadPrice(leadId, token).then((res) => {
        const data = res;
  
        setProductCard(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId, token]);

  useEffect(() => {
    if (DOMAIN === "sql_es") {
      fetchPaymentPrices();
    }
  }, [leadId, token]);

  const handleClickIcon = async () => {
    if (sqlInfo?.promotion?.promotionalCode || productCard?.isPromoted) {
      await handleRemoveCoupon("");
    } else {
      setShowModalCoupon(true);
    }
  };

  const fetchPromo = async (code) => {
    try {
      setPromoLoading(true);
      const response = await couponsRepository.applyCoupon({
        promotionalCode: code,
      });
      setValidateCoupon("validate");
      setPromoLoading(false);
      if (DOMAIN === "sql_es") {
        fetchPaymentPrices();
      } else {
        apiLeadPrice(leadId, token).then((res) => {
          setProductCard(res);
        });
      }
    } catch (error) {
      setValidateCoupon("invalidate");
      setPromoLoading(false);
    }
  };

  const handleRemoveCoupon = async (code) => {
    try {
      setPromoLoading(true);
      await couponsRepository.removeCoupon({
        promotionalCode: code,
      });
      setValidateCoupon("error");
      setShowNotification({
        message: translate("checkpoint.coupon.remove.correct"),
        status: true,
        type: "success",
        autoHideDuration: 5000,
      });
      if (DOMAIN === "sql_es") {
        fetchPaymentPrices();
      } else {
        apiLeadPrice(leadId, token).then((res) => {
          setProductCard(res);
        });
      }
      setPromoLoading(false);
    } catch (error) {
      setPromoLoading(false);
    }
  };

  useEffect(() => {
    setValidateCoupon("");
  },[showModalCoupon]);

  return (
    <>
      <CouponSelectorModal
        open={showModalCoupon}
        onClose={() => setShowModalCoupon(false)}
        validateCoupon={validateCoupon}
        fetchPromo={(coupon) => fetchPromo(coupon)}
        promoLoading={promoLoading}
        selector={true}
      />
      <PaymentMain
        brokerId={brokerId}
        election={election}
        handlePaymentCard={handlePaymentCard}
        handlePaymentSepa={handlePaymentSepa}
        undoSelectedPaymentMethod={undoSelectedPaymentMethod}
        leadId={leadId}
        paymentCard={paymentCard}
        paymentSepa={paymentSepa}
        productCard={productCard}
        setShowModalCoupon={setShowModalCoupon}
        token={token}
        translate={translate}
        broker={broker}
        utm={utm}
        sqlInfo={sqlInfo}
        setShowNotification={setShowNotification}
        handleClickIcon={handleClickIcon}
      />
    </>
  );
};

export default Payment;
